.training-courses {
	padding-bottom: 60px;
	
	h1 {
		.g(30);
		text-align: center;
		color: @red;
		margin-bottom: 0;
	}
	h2 {
		.m(12);
		font-weight: 600;
		text-align: center;
	}
	
	.classes {
		margin-top: 40px;
	}
		
	.class {
		display: grid;
		border-radius: @corner-radius-std;
		background-color: @grey-box-back;
		overflow: hidden;
		row-gap: 20px;
		
		@side-padding: 40px;
		
		.msg {
			padding: @side-padding;

			h2 {
				.m(10);
				color: @blue-light;
				margin-top: 0;
				margin-bottom: 0;
				text-align: left;
				font-weight: 700;
			}
			h3 {
				.g(14);
				color: @red;
				margin-top: 10px;
				margin-bottom: 0;

				small {
					.m(10);
					display: block;
				}
			}
			
			p {
				.m(8.8);
			}
		}
		
		.pic-holder {
			font-size: 0;
			figure {
				margin: 0;
				height: 100%;
				* {
					height: 100% !important;
				}
			}
			img {
				object-fit: cover;
				object-position: center center;
				width: 100%;
			}
			p {
				display: none;
			}
			img {
				display: block;
			}
		}
		
		.registration {
			padding-right: 30px;
			padding-top: 15px;
			
			.color-button {
				margin-right: 0;
			}

			h2, h3 {
				color: @blue-light;
				margin-top: 0;
				margin-bottom: 0;
				text-align: left;
			}
			h2 {
				.m(22);
				font-weight: 700;
			}
			h3 {
				.m(10);
			}
		}

		.tablet-and-smaller({
			@side-padding: 20px;
			.msg {
				padding: @side-padding @side-padding 0 @side-padding;
			}
			.registration {
				padding-left: @side-padding;
				padding-bottom: @side-padding;
			}
		});
		
		.tablet({
			grid-template-columns: 255px 1fr auto;
			.registration {
				h2, h3 {
					text-align: right;
				}
			}
		});
	}
	
	.class + .class {
		margin-top: 30px;
	}
	
	&.red {
		background-color: @red;
		padding-top: 40px;
		.class {
			.tablet({
				grid-template-columns: 345px 1fr auto;
			});
		}
	}
	
}

.advanced-training-programs {
	display: grid;
	grid-template-columns: 
		1fr 
		minmax(auto, @container-width / 5) 
		minmax(auto, 3 * @container-width / 5) 
		minmax(auto, @container-width / 5) 
		1fr;
	overflow: hidden;
	column-gap: 20px;
	background-color: @grey-box-back;

	.content {
		grid-row: 1;
		grid-column: 2 / span 3;
		z-index: 3;
	}
	.right {
		position: relative;
		grid-column: 4 / span 2;
		grid-row: 1;
		.holder {
			position: absolute;
			// top: 0;
			left: 0px;
			top: 50%;
			transform: translateY(-50%);
			min-height: 100%;
			min-width: 100%;
			text-align: right;
			font-size: 0;
			height: 100%;
		}
		img {
			min-height: 100%;
			width: auto !important;
		}
	}

	.left {
		position: relative;
		grid-column: 1 / span 2;
		grid-row: 1;

		.holder {
			position: absolute;
			// top: 0;
			right: 0px;
			top: 50%;
			transform: translateY(-50%);
			min-height: 100%;
			min-width: 100%;
			text-align: left;
			font-size: 0;
			height: 100%;
		}

		img {
			min-height: 100%;
			width: auto !important;
		}
	}
	
	.content {
		padding-bottom: 40px;
		h1 {
			.g(30);
			color: @red;
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}
		
		.advanced-programs {
			@spacing-gap: 30px;
			display: grid;
			row-gap: @spacing-gap;
			column-gap: @spacing-gap;

			@media (min-width: 1050px) {
				grid-template-columns: repeat(3, 1fr);
			};

			.program {
				border-radius: @corner-radius-std;
				display: grid;
				grid-template-rows: 1fr auto;
				padding: 50px 40px;
				
				position: relative;
				isolation: isolate;
				& > picture:first-child {
					grid-row: 1 / span 2;
					grid-column: 1;
					user-select: none;
					img {
						position: absolute;
						// width: 100%;
						// height: 100% !important;
						bottom: 0;
						right: 0;
						// object-fit: cover;
						// object-position: center center;
						z-index: -1;
						user-select: none;
						max-width: 100%;
						max-height: 100%;
					}
				}

				.txt {
					color: white;
					grid-row: 1;
					grid-column: 1;

					h2 {
						.g(14);
						margin-top: 0;
						margin-bottom: 0;
					}
					p {
						.m(9);
					}
				}
				.buttons {
					grid-row: 2;
					grid-column: 1;
					p {
						margin: 0;
					}
				}
				
				&.academy {
					background-color: @blue-light;
				}
				&.private {
					background-color: @purple;
				}
				&.citizen {
					background-color: @red;
				}
				
				.big-phone-and-smaller({
					padding: 50px @page-side-padding 30px @page-side-padding;

				});
				
			}
		}
	}
}

.meet-our-trainers {
	padding-bottom: 50px;

	h1 {
		.g(30);
		color: @red;
		text-align: center;
	}
	
	.trainers {
		.trainer {
			.clear-inner;
			border-radius: @corner-radius-std;
			border: solid 2px @trainer-box-border;
			padding: @page-side-padding;
			
			.pic-holder {
				img {
					display: block;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 20px;
					border-radius: @corner-radius-std;
					max-width: 100%;
				}
			}
			
			.info {
				h2 {
					.g(14);
					color: @red;
					margin-top: 0;
					margin-bottom: 0;
				}
				ul {
					.red-check;
				}
			}
			
			.tablet({
				padding: 30px;
				.pic-holder {
					img {
						float: left;
						margin-right: 20px;
					}
				}
				.info {
					ul {
						position: relative;
						left: 37px;
					}
				}
			});
			
		}
		
		.trainer + .trainer {
			margin-top: 30px;
		}
	}
}

.k9-page-intro {
	display: grid;
	grid-template-columns: 1fr auto;
	column-gap: 30px;
	margin-bottom: 40px;
	margin-top: 50px;

	.txt {
		max-width: 800px;
		h1 {
			.g(23);
			color: @red;
		}
		p {
			.m(9);
			font-weight: 600;
		}

	}
	
	.buttons {
		align-self: end;
	}
	.tablet-and-smaller({
		grid-template-columns: auto;

	});

}

.k9-classes {
	margin-bottom: 50px;
	.class {
		padding: 30px;
		border-radius: @corner-radius-std;
		background-color: @grey-box-back;
		
		h2, h3 {
			margin: 0;
		}
		
		h2 {
			.m(10);
		}
		h3 {
			.g(14);
			color: @red;
		}
		
		h4 {
			.m(14);
			color: @blue-light;
			margin-top: 30px;
			margin-bottom: 0;
			
			small {
				display: block;
				.m(8);
			}
		}
		
		p {
			.m(8);
		}
	}
	
	.class + .class {
		margin-top: 20px;
	}
}

.good-citizen-msg {
	h1 {
		.g(23);
		color: @red;
		margin-top: 0;
		
	}
	p {
		.m(9);
		font-weight: 300;
	}
	.content {
		align-self: start;
	}
	.pic-holder {
		.rounded-square-picture;
	}
}

.therapy-seminar-msg {
	margin-top: 50px;
	margin-bottom: 50px;
	.container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 20px;
		
		.desc {
			h1 {
				.g(23);
				margin-top: 0;
				margin-bottom: 0;
				color: @red;
			}
			p {
				.m(9);
			}
		}
		
		.tablet-and-smaller({
			grid-template-columns: auto;

		});
	}
	
	.when {
		text-align: right;
		.ticket {
			background-color: @grey-box-back;
			border-radius: @corner-radius-std;
			padding: 22px 25px;
			display: inline-grid;
			grid-template-columns: 1fr auto;
			column-gap: 15px;
			
			.clock {
				display: inline-block;
				width: 15px;
			}
			
			.details {
				text-align: left;
				h2 {
					.g(14);
					color: @red;
				}
				
				h3 {
					.m(10);
					color: @red;
					margin-bottom: 0;
				}
				
				p {
					.m(8);
				}

				h3 + p {
					margin-top: 0;
				}
			}
			
			.price {
				color: @blue-light;
				text-align: right;
				h2 {
					.m(22);
					margin: 0;
				}
				p {
					.m(10);
					font-weight: 600;
					margin: 0;
					small {
						.m(8);
						display: block;
						font-weight: 400;
					}
				}
			}
		}
		
		.tablet-and-smaller({
			.ticket {
				display: grid;
				grid-template-columns: auto auto;
				max-width: 400px;
				margin-left: auto;
				margin-right: auto;
				.details {
					h2 {
						margin-top: 0;
					}
				}
			}
		});
		@media (max-width: 450px) {
			.ticket {
				grid-template-columns: auto;
				.price {
					text-align: left;
				}
			}
		}
	}
}
