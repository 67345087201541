.container {
	max-width: 100%;
	margin: auto;
	
	padding-left: @page-side-padding;
	padding-right: @page-side-padding;
	
	.desktop({
		max-width: @container-width;
		padding-left: 0;
		padding-right: 0;
	});
}

.container-wrapper {
	padding-left: @page-side-padding;
	padding-right: @page-side-padding;

	.container {
		padding-left: 0px;
		padding-right: 0px;
			
	}
}

.inline-block {
	display: inline-block;
}

.full-back {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.use-picture-as-background {
    position: relative;
    isolation: isolate;
    & > picture:first-child {
        user-select: none;
        img {
            position: absolute;
            width: 100%;
            height: 100% !important;
			top: 0;
			left: 0;
            object-fit: cover;
            object-position: center center;
            z-index: -1;
            user-select: none;
        }
    }
}


.ratio(@ratio) {
	&:before {
		content: '\00a0';
		display: inline-block;
		width: 0;
		font-size: 0;
		height: 0;
		padding-top: @ratio / 2 * 100%;
		padding-bottom: @ratio / 2 * 100%;
		vertical-align: middle;
	}
	@supports (aspect-ratio: @ratio) {
		aspect-ratio: 1 / @ratio;
	}
	@supports (aspect-ratio: @ratio) {
		&:before {
			display: none;
		}
	}
}
/*
.ei-grid {
	#ei-grid-1.grid;
}

.ei-gutterless-grid {
	#ei-grid-1.gutterless-grid;
}
*/


.clear {
	clear: both;
}

.clear-inner {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.background-color-fade(@color, @opamount) {
    background-color: @color;
    background-color: fade(@color, @opamount);
}

.horz-back-grad(@left, @right) {
  background: -webkit-linear-gradient(left, @left , @right); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, @left , @right); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, @left , @right); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, @left , @right); /* Standard syntax */
}

.nowrap {
    white-space: nowrap;
}

.fs(@font-size) {
	font-size: @base-font-size * @font-size / 10;
}

@media (max-width: @container-width) {
	.only-desktop {
		display: none;
	}
}

.desktop(@rules) {
	@media(min-width: @container-width) {
		@rules();
	}
}

.desktop-and-smaller(@rules) {
    @media (max-width: @container-width) {
        @rules();
    }
}

.tablet(@rules) {
	@media(min-width: @tablet-width) {
		@rules();
	}
}

.tablet-and-smaller(@rules) {
    @media (max-width: @tablet-width) {
        @rules();
    }
}

.big-phone-and-smaller(@rules) {
    @media (max-width: @big-phone-width) {
        @rules();
    }
}

.big-phone(@rules) {
	@media(min-width: @big-phone-width) {
		@rules();
	}
}

.phone-and-smaller(@rules) {
    @media (max-width: @phone-width) {
        @rules();
    }
}

.phone(@rules) {
	@media(min-width: @phone-width) {
		@rules();
	}
}

.iphone-landscape-and-smaller(@rules) {
    @media (max-width: @iphone-4-landscape-width) {
        @rules();
    }
}

.iphone-landscape(@rules) {
	@media(min-width: @iphone-4-landscape-width) {
		@rules();
	}
}

.primative(@rules) {
	.ie6, .ie7, .ie8 {
		@rules();
	}
}


