
.get-in-touch {
	column-gap: 20px;
	h1 {
		.g(30);
		margin-bottom: 0;
		color: @red;
	}
	.top img {
		top: -400px;
	}
	
	.phones {
		img {
			display: inline-block;
		}
	}
}
.map-picture {
	position: relative;
	img {
		display: block;
		width: 100%;
	}
	
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.directions {
	background-color: @grey-box-back;
	padding-top: 30px;
	padding-bottom: 30px;
	
	.container {
		text-align: center;
		
		p {
			.m(7.83);
		}
	}
}

.contact-form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
	margin-bottom: 60px;
	
	
	label {
		grid-column: span 2;
		.msg {
			font-weight: 600;
		}
		.ast {
			color: @red;
		}

		margin-bottom: 12px;
		
	}
	
	input, textarea, select {
		display: block;
		box-sizing: border-box;
		width: 100%;
		margin-top: 5px;
		border-radius: 10px;
		border: solid 1px @grey-box-lines;
		background-color: white;
		padding: 9px 15px;
	}
	
	button {
		margin-top: 20px;
	}
	
	.tablet({
		label.name, label.phone {
			grid-column: span 1;
		}

	});
}

.hours-list {
	background-color: @grey-box-back;
	border-radius: @corner-radius-std;
	padding: 20px 35px 40px 35px;
	margin-top: 30px;

	
	h2 {
		.g(12);
		color: @red;
	}
	
	h3 {
		.m(10);
		color: @red;
		margin-bottom: 5px;
	}
	
	p {
		.m(7);
		margin-top: 0;
	}
	.phones {
		strong {
			display: inline-block;
			width: 130px;
			color: @red;
			font-size: 120%;
		}
		
		img {
			width: 20px;
			padding-right: 7px;
			vertical-align: sub;
		}
	}
}