// .event-controls {
// 	margin-bottom: 60px;
// }


.events-listing {
	margin-bottom: 60px;
}

.event-controls-bar {
	display: grid;
	column-gap: 70px;
	row-gap: 40px;
	margin-bottom: 60px;

	.search {
		display: grid;
		grid-template-columns: 1fr auto;
		column-gap: 22px;
	}

	.color-button {
		.m(9);
		padding: 8px 25px;
		min-width: unset;
		margin: 0;
	}

	input {
		display: block;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		border-radius: 10px;
		border: solid 1px @grey-box-lines;
		background-color: white;
		padding: 9px 15px;
	}

	.mode {
		display: grid;
		grid-template-columns: auto auto;
		column-gap: 12px;
		text-align: center;
	}
	
	.tablet({
		grid-template-columns: 1fr auto;
	});
	
	.big-phone-and-smaller({
		.search {
			grid-template-columns: auto;
			row-gap: 10px;
			text-align: center;
		}

	});
}


.event-details {
	display: grid;
	grid-template-columns: auto 1fr minmax(auto, 300px);
	column-gap: 42px;
	row-gap: 30px;
	padding-bottom: 40px;


	.title {
		.g(23);
		color: @red;
		grid-column: 1 / span 2;
		grid-row: 1;
		margin-top: 0;

	}

	.date {
		text-align: center;
		padding-left: 10px;

		.year,
		.month {
			.m(7);
			text-transform: uppercase;
			font-weight: 500;
		}

		.day {
			.m(15);
			font-weight: 700;
			;
			color: @red;
		}
	}
	
	.bottom {
		display: contents;
	}

	.info {
		h2 {
			.g(14);
			color: @red;
			margin-top: 0;
			margin-bottom: 10px;
		}

		div,
		p {
			.m(7);
		}

		.details {
			margin-top: 20px;

			p {
				.m(9);
			}
		}

		.txt {
			vertical-align: super;
		}
	}

	.thumbnail {
		.rounded-picture;
		grid-column: 3;
		grid-row: 1 / span 2;

		img {
			max-width: 100%;
		}
	}
	
	.tablet-and-smaller({
		grid-template-columns: auto 1fr;
		
		.title {
			margin-bottom: 0;
		}
		
		.thumbnail {
			grid-row: 2;
			grid-column: 1 / span 2;
			img {
				display: block;
				margin-left: auto;
				margin-right: auto;
				max-width: 100%;
			}
			
		}
		.bottom {
			display: block;
			grid-column: 1 / span 2;
			
			.date {
				float: left;
				margin-right: 30px;
				margin-bottom: 15px;
			}
		}

	});

}

.event-row {
	display: grid;
	grid-template-columns: auto 1fr minmax(auto, 300px);
	row-gap: 40px;
	column-gap: 42px;
	padding-top: 40px;
	padding-bottom: 40px;

	border-top: solid 2px @grey-box-lines;
	text-decoration: none;
	color: inherit;

	.date {
		text-align: center;

		.year,
		.month {
			.m(7);
			text-transform: uppercase;
			font-weight: 500;
		}

		.day {
			.m(15);
			font-weight: 700;
			;
			color: @red;
		}
	}

	.info {
		h2 {
			.g(14);
			color: @red;
			margin-top: 0;
			margin-bottom: 10px;
		}

		div,
		p {
			.m(7);
		}

		.shortDesc {
			margin-top: 20px;
		}

		.txt {
			vertical-align: super;
		}
	}

	.thumbnail {
		.rounded-picture;

		img {
			max-width: 100%;
		}
	}
	
	.tablet-and-smaller({
		grid-template-columns: auto 1fr;
		.thumbnail {
			grid-column: 1 / span 2;
			img {
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}

	});

}

.month-header {
	display: grid;
	grid-template-columns: auto auto 1fr;
	column-gap: 10px;
	padding: 20px 0;
	margin-bottom: 60px;
	border-bottom: solid 2px @grey-box-lines;
	;

	button {
		background: transparent;
		border: none;
	}

	.date {
		.m(14);
		font-weight: 300;
	}
}

.header-grid {
	display: grid;
	grid-template-columns: repeat(7, 1fr);

	.day-name {
		text-align: center;
		padding: 3px;
	}
	
	.big-phone-and-smaller({
		.day-name {
			font-size: 0;
			&:first-letter {
				.m(7);
			}
		}

	});
}

.days-grid {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	background-color: @grey-box-lines;
	padding: 1px;
	column-gap: 1px;
	row-gap: 1px;

	.day-of-month {
		.m(7);
		font-weight: 600;
		margin: 9px 12px;
	}

	.grid-day {
		min-height: 200px;
		background-color: white;

		.events-of-the-day {
			margin: 10px;
		}

		.grid-day-event {
			color: @red;

		}
	}

	.non-cur-month {
		.day-of-month {
			.m(7);
			opacity: .4;
		}
	}
}

.breadcrumbs {
	margin-top: 30px;
	margin-bottom: 60px;

	a {
		.m(7);
		color: @grey-body-text;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	.cur {
		.m(7);
		font-weight: 600;
		color: @red;
	}
}

.homepage-events-list {
	display: grid;
	column-gap: 15px;
	margin-top: 40px;
	margin-bottom: 60px;
	
	.tablet({
		grid-template-columns: repeat(3, 1fr);

	});

	.homepage-event {
		text-align: left;
		min-height: 100%;
		display: grid;
		grid-template-rows: auto 1fr auto;

		.pic-holder {
			background-color: white;
			border-top-left-radius: 18px;
			border-top-right-radius: 18px;
			img {
				display: block;
				aspect-ratio: 1.508064517;
				object-fit: cover;
				object-position: center;
				border-top-left-radius: 18px;
				border-top-right-radius: 18px;
				width: 100%;
			}
		}

		.msg {
			background-color: white;
			padding: 25px 35px;

			h2 {
				.g(14);
				color: @red;
				font-weight: 600;
				margin-bottom: 10px;
				margin-top: 0;
				padding-left: 5px;

				small {
					.m(10);
					display: block;
					color: @grey-body-text;
				}
			}
			
			.short-desc {
				margin-top: 15px;
			}
			
			.when-where {

				.txt {
					vertical-align: super;
				}
			}

			p, li {
				.m(7);
				font-weight: 300;
			}
			p {
				margin-bottom: 0;
			}
		}
		.buttons {
			margin: 0;
			padding: 5px 35px 25px 35px;
			background-color: white;
			border-bottom-left-radius: 18px;
			border-bottom-right-radius: 18px;
		}
	}

}

.download-newsletter {
	background-color: @red;
	padding-top: 1px;
	padding-bottom: 50px;
	overflow: hidden;
	// background-image: url('/img/events/)
	

	h1 {
		.gelica(23);
		color: white;
		margin-bottom: 0;
	}

	p {
		.m(9);
		color: white;
	}

	.container {
		position: relative;
		
		& > picture:first-child {
			img {
				position: absolute;
				right: 0;
			}
		}
	}

	.double-column {
		.col2 {
			padding-left: clamp( 20px, 100px, 10vw);
			padding-right: clamp( 20px, 100px, 10vw);
			*:first-child {
				margin-top: 0px;
			}
			p {
				text-align: center;
			}
		}
	}
}