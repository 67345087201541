
.dog-park-passes {
	.content {
		padding-bottom: 40px;
		padding-right: @page-side-padding;
		z-index: 3;
	}

	h1 {
		.g(30);
		color: @red;
		margin-bottom: 0;
	}

	p {
		.m(9);
		font-weight: 300;
	}

	h2 {
		.g(14.31);
		color: @red;
		margin-bottom: 15px;
	}
	
	h2 + p {
		.m(8);
		font-weight: 400;
	}
	
	.passes {
		.pass-button {
			width: 230px;
		}
	}
	
	.tablet({
		.right {
			.holder {
				top: 0;
				// min-height: 100%;
			}
			img {
				// width: auto;
			}
		}

	});
	
	.tablet-and-smaller({
		
		.right {
			img {
				filter: grayscale(1);
				opacity: .1;
			}
		}
		
		.pass-button {
			margin-bottom: 10px;
		}

	});
}

.check-out-park {
	background-color: @grey-box-back;
	.container {
		padding-top: 1px;
		padding-bottom: 50px;
		text-align: center;
	}
	
	h1 {
		.g(14.31);
		color: @red;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.dog-park-video {
		video {
			display: block;
			width: 100%;
			margin: auto;
			max-width: 840px;
			border-radius: @corner-radius-std;
		}
	}
}

.share-adventure {
	background-color: @red;
	color: white;
	padding: 15px 30px;
	text-align: center;
	
	h1 {
		.g(30);
		margin-top: 30px;
		margin-bottom: 0;
	}
	h2 {
		.m(12);
		font-weight: 500;
		margin-top: 10px;
	}
	
	.big-phone-and-smaller({
		h1 {
			font-size: 9vw;
		}
	})
}

.testimonials {
	padding-top: 30px;
	padding-bottom: 30px;
	
	h1, h2 {
		color: white;
		text-align: center;
	}
	h1 {
		.g(30);
		margin-bottom: 0;
		margin-top: 20px;
	}
	h2 {
		.m(12);
		margin-top: 10px;
		font-weight: 500;
	}
}