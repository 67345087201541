.pet-resort-boarding {
	background-color: @grey-box-back;
	
	.content {
		h1 {
			.g(30);
			color: @red;
			margin-top: 0;
			margin-bottom: 0;
		}
		p {
			.m(9);
			font-weight: 300;
		}
		.tablet({
			padding-right: 80px;
		});
	}
	.pic-holder {
		.rounded-square-picture;
	}
}

section.reservation-types {
	text-align: center;
	h1 {
		.g(30);
		color: @red;
		margin-bottom: 0;
	}
	h2 {
		.m(12);
		font-weight: 300;
		margin-bottom: 0;
		margin-top: 0;
	}
	p {
		.m(12);
		font-weight: 600;
		a {
			color: @red;
		}
		
		img {
			padding-bottom: 3px;
			display: inline-block;
			vertical-align: middle;
		}
	}

	h2 + p {
		margin-top: 4px;
	}
	
	.reservation-types-list {
		list-style: none;
		display: grid;
		column-gap: 15px;
		row-gap: 15px;
		margin-top: 40px;
		margin-bottom: 60px;
		padding-left: 0;
		
		.tablet({
			grid-template-columns: repeat(2, 1fr);

		});
		
		& > li {
			text-align: left;
			min-height: 100%;
			display: grid;
			grid-template-rows: auto 1fr;
			.pic-holder {
				img {
					display: block;
					aspect-ratio: 1.508064517;
					object-fit: cover;
					object-position: center;
					border-top-left-radius: 18px;
					border-top-right-radius: 18px;
					width: 100%;
				}
			}
			.msg {
				background-color: @grey-box-back;
				border-bottom-left-radius: 18px;
				border-bottom-right-radius: 18px;
				padding: 30px 35px;
				
				h2 {
					.g(14);
					color: @red;
					font-weight: 600;
					
					small {
						.m(10);
						display: block;
						color: @grey-body-text;
					}
				}
				.price {
					.m(22);
					color: @blue-light;
					font-weight: 700;
					small {
						display: block;
						.m(9);
					}
				}
				p {
					.m(6.5);
					font-weight: 300;
					margin-bottom: 0;
				}
			}
		}

	}
}


section.add-ons {
	background-color: @purple;
	padding-top: 10px;
	padding-bottom: 60px;
	background-image: url('/icons/purple-paws-repeating-2.svg');
	
	h1 {
		.g(30);
		color: white;
		text-align: center;
	}
	& > picture:first-child {
		img {
			// opacity: .6;
		}
	}
	.addon-options {
		list-style: none;
		padding-bottom: 1px;
		padding-left: 0;
		
		li {
			background-color: white;;
			border-radius: @corner-radius-std;
			padding: 15px 15px;
			margin-bottom: 15px;
			
			details {
				position: relative;
				
			}
			
			summary {
				display: grid;
				column-gap: 20px;
				row-gap: 20px;
				.tablet({
					grid-template-columns: 185px 1fr; 

				});
				
				&:before {
					content: 'EXPAND TO VIEW ALL +';
					.m(9);
					font-weight: 600;
					color: @grey-box-lines;
					text-align: right;
				}


				
				.large {
					font-size: 140%;
				}

				.pic-holder {
					.rounded-square-picture;
					
					img {
						display: block;
						margin-left: auto;
						margin-right: auto;
					}
				}
				
				h2 {
					.g(23);
					color: @red;
					margin-top: 0;
					margin-bottom: 0;
				}
				p {
					.m(8.8);
				}
				.tablet({
					&:before {
						position: absolute;
						top: 26px;
						right: 15px;
					}

				});
			}
			
			details[open] {
				summary {
					&:before {
						content: '-';
					}
				}
			}
			
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.addons-pricing {
	width: 100%;
	margin-bottom: 20px;
	margin-top: 20px;
	td {
		border-bottom: 1px solid @grey-box-lines;
	}

	td {
		padding: 10px 0;
	}
	td:first-child {
		.g(14);
		color: @red;
		font-weight: 600;
	}
	td:last-child {
		.m(15);
		font-weight: 600;
		color: @blue-light;
		text-align: right;
		
		sup {
			.m(11);
		}
	}
	
	small {
		font-size: 50%;
		span {
			display: block;
		}
	}
}

.boarding-membership {
	background-color: @purple;
	.pic-holder {
		align-self: center;
		.rounded-square-picture;
	}
	.content {
		color: white;
		h1 {
			.g(30);
			margin-bottom: 24px;
		}
		
		p, li {
			.m(8.5);
			font-weight: 300;
		}
		
		li {
			margin-bottom: 3px;
		}
		
		p + ul {
			margin-top: -15px;
		}
		
		.price {
			display: inline-block;
			.m(20);
			font-weight: 600;;
			margin-right: 15px;
			vertical-align: bottom;
			
			sup {
				.m(10);
			}
			
			small {
				display: block;
				.m(10);
			}
		}
		
	}
}