.setup-width-definitions() {
	@container-width: 1170px;
	@tablet-width: 900px;
	@tablet-small-width: 716px;
	@big-phone-width: 650px;
	@phone-width: 550px;
	@iphone-4-landscape-width: 480px;
	@iphone-4-width: 320px;
	@page-side-padding: 10px;
	@outside-page-padding: 58px;

}

.setup-width-definitions();


/*
 * The base font size which makes the chosen font match photoshop.
 * For Open Sans this is 7.1pt, for Source Sans Pro this is 7.6pt 
 */
@base-font-size: 7.6pt;
@gelica-base-font-size: 15.391798107pt;
@monteserrat-base-font-size: 15.860869565pt;

@corner-radius-std: 			18px;

@grey-body-text: 		#474746;

@grey-box-back: 		#f9f5ee;
@trainer-box-border: 	#f0ebe3;
@grey-box-lines: 		#c6beaf;
@grey: 					#474746;

@red: 					#b53f2a;


@blue-light: 			#61a7ae;
@blue-dark: 			#398f97;

@yellow: 				#fab62d;

@purple: 				#72749f;

@font-face {
	font-family: 'Fraunces';
	font-display: swap;
	font-weight: 100 900;
	src: url('/fonts/Fraunces[SOFT,WONK,opsz,wght].woff2') format('woff2');
}

@font-face {
	font-family: 'Fraunces';
	font-display: swap;
	font-weight: 100 900;
	font-style: italic;
	src: url('/fonts/Fraunces-Italic[SOFT,WONK,opsz,wght].woff2') format('woff2');
}

.source-sans-pro() {
	font-family: 'Source Sans Pro', sans-serif;
}

.open-sans() {
	font-family: 'Open Sans', sans-serif;
}

.menu-link() {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.gelica(@size) {
	// font-family: Fraunces, sans-serif;
	font-family: gelica, sans-serif;
	font-size: @gelica-base-font-size * @size / 10;
	font-size: clamp(8px, @gelica-base-font-size * @size / 10, 12vw);
	// font-size: min(@gelica-base-font-size * @size / 10, 12vw);
}

.g(@size) {
	.gelica(@size);
}

.monteserrat() {
	font-family: "Montserrat", serif;
	font-optical-sizing: auto;
	font-style: normal;
}
.monteserrat(@size) {
	.monteserrat;
	font-size: @monteserrat-base-font-size * @size / 10;
}

.m(@size) {
	.monteserrat(@size);
}

.link-inherit() {
	a {
		.menu-link;
		font-size: inherit;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		font-family: inherit;
	}
}


h1,
h2,
h3,
h4,
h5 {
	.link-inherit;
}

h1 {
	.gelica(30);
}

body {
	.monteserrat;
	color: @grey-body-text;
	p {
		line-height: 1.555em;
	}
}