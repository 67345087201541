@import (inline) "normalize.css";

@import "definitions";
// @import "../node_modules/webhandle-menus-1/less/webhandle-menus-1/drop-down-menu.less";
// @import (reference) "../node_modules/ei-grid-1/less/grid.less";
@import "../node_modules/@dankolz/ei-css-grid/grid.less";

.ei-form {
	@import "../node_modules/ei-form-styles-1/less/ei-form-styles-1";
}

.webhandle-file-tree-image-browser .ei-form {
    @import "../node_modules/ei-form-styles-1/less/ei-form-styles-1";
}

@import "../node_modules/@dankolz/ck-double-column/less/components";

@import "../node_modules/@dankolz/webp-detection/less/picture-background.less";
@import (reference) "images";

@import "basics";
@import "circular-stripe";
@import "components";

@import "layouts";

@import "pages/home";
@import "pages/grooming";
@import "pages/boarding";
@import "pages/careers";
@import "pages/contact";
@import "pages/dog-park";
@import "pages/playcare";
@import "pages/temporary-paws";
@import "pages/training";
@import "pages/events";
@import "pages/our-team";