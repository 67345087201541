
.policies {
	margin-bottom: 30px;
	h1 {
		.gelica(23);
		color: @blue-light;
		text-align: center;
	}
	
	p {
		.monteserrat(8);
		font-weight: 300;
		text-align: center;
	}
}

.grooming-addons {
	background-color: @blue-light;
	overflow: hidden;
	padding-top: 20px;
	padding-bottom: 100px;

    & > picture:first-child {
        img {
            height: auto !important;
            object-fit: cover;
            object-position: top center;
			top: -30px;
        }
    }
	
	h1 {
		color: white;
		text-align: center;
		.gelica(30);
	}
	
	.addons-box {
		height: 100%;
	}
	
	.double-column {
		row-gap: 40px;
	}
}

.prebook-discount {
	background-color: @grey-box-back;
	@offset: 100;
	grid-template-columns: 1fr minmax(auto, @container-width / 2 + @offset) minmax(auto, @container-width / 2 - @offset) 1fr;
	.content {
		padding-top: 30px;
		padding-bottom: 50px;
		
		h1 {
			.gelica(30);
			color: @yellow;
			margin: 0;
		}
		p {
			.monteserrat(9);
			font-weight: 300;
		}
		
		li {
			.monteserrat(8);
		}
	}
	
	.right {
		.holder {
			height: 100%;
			img {
				object-fit: cover;
				object-position: left center;
			}
		}
	}

}
