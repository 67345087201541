
.meet-our-team {
	padding-bottom: 50px;

	h1 {
		.g(30);
		color: @red;
		text-align: center;
	}
	
	.team-members {
		.team-member {
			border-radius: @corner-radius-std;
			border: solid 2px @trainer-box-border;
			padding: @page-side-padding;
			position: relative;
			overflow: hidden;
			
			max-height: 388px;
			transition: max-height .3s;
			
			.sizer {
				.clear-inner;
			}
			
			.expander {
				position: absolute;
				right: 10px;
				top: 10px;
								
				.m(12);
				font-weight: 600;
				color: @grey-box-lines;
				text-align: right;
				cursor: pointer;
				.when-open  {
					.m(14);
				}
			}
			
			.when-open {
				display: none;;
			}
			
			&.open {
				.when-open {
					display: block;
				}
				.when-closed {
					display: none;
				}
			}
			
			.pic-holder {
				img {
					display: block;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 20px;
					border-radius: @corner-radius-std;
					max-width: 100%;
				}
			}
			
			.info {
				h2 {
					.g(14);
					color: @red;
					margin-top: 0;
					margin-bottom: 0;
				}
				h3 {
					.m(7);
					font-weight: 400;
					color: @red;
					margin-top: 0;
					margin-bottom: 0;
				}
				ul {
					.red-check;
				}
			}
			
			.bottom {
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;

				.fade {
					position: relative;
					height: 30px;
					background: linear-gradient(transparent 0%, rgba(255, 255, 255, 0.2) 14%, rgba(255, 255, 255, 0.6) 35%, rgba(255, 255, 255, 0.9) 56%, rgba(255, 255, 255, 1) 83%);
				}

			}
			
			.tablet({
				padding: 30px;
				max-height: 288px;
				.pic-holder {
					img {
						float: left;
						margin-right: 20px;
					}
				}
				.info {
					ul {
						position: relative;
						left: 37px;
					}
				}
			});

			
		}
		
		.team-member + .team-member {
			margin-top: 30px;
		}
	}
}

.type-of-person {
	margin-top: 12px;
	.dog, .cat {
		display: grid;
		grid-template-columns: 40px 1fr;
	}
	
	.icon {
		text-align: center;
	}
	.txt {
		.m(6);
		align-self: center;
		padding-bottom: 3px;
	}
	
	&.dog-person {
		.cat {
			filter: grayscale(1);
			opacity: .6;
		}
	}

	&.cat-person {
		.dog {
			filter: grayscale(1);
			opacity: .6;
		}
	}
}