.join-our-team {
	h1 {
		.g(30);
		margin-bottom: 0;
		color: @red;
	}
	.pic-holder {
		align-self: center;
	}
}

.job-openings {
	padding-top: 10px;
	padding-bottom: 60px;
	background-color: @grey-box-back;
	
	h1 {
		.g(30);
		color: @blue-light;
		text-align: center;
	}
	
	.job-description {
		padding: 20px 30px;
		background-color: white;
		border-radius: @corner-radius-std;
		border: solid 1px @grey-box-lines;

		h2 {
			.g(14);
			color: @red;
			margin-top: 0;
			margin-bottom: 10px;
		}
		.wage {
			.m(15);
			color: @blue-light;
			font-weight: 600;
			
			sup {
				.m(11);
				vertical-align: sub;
			}
		}
		
		.time {
			.m(7);
			position: relative;
			padding-left: 30px;
			
			&:before {
				content: url('/icons/clock-red.svg');
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		
		.desc {
			p, li, div {
				.m(8);
			}
		}

	}
	.job-description + .job-description {
		margin-top: 20px;
	}
}