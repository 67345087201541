.playcare-reservation-types {
	padding-bottom: 60px;
	
	h1 {
		.g(30);
		text-align: center;
		color: @red;
	}
	
	.types {
		margin-top: 40px;
	}
		
	.reservation {
		display: grid;
		border-radius: @corner-radius-std;
		background-color: @grey-box-back;
		overflow: hidden;
		
		.msg {
			padding: 40px;
		}
		
		.pic-holder {
			font-size: 0;
			figure {
				margin: 0;
				height: 100%;
				* {
					height: 100% !important;
				}
			}
			p {
				display: none;
			}
			img {
				display: block;
				margin-left: auto;
				margin-right: auto;
				width: 100%;
			}
		}
		
		h2 {
			.g(14);
			color: @red;
			margin-top: 0;
			margin-bottom: 0;
		}
		h3 {
			.m(22);
			color: @blue-light;
			margin-top: 10px;
			margin-bottom: 0;

			small {
				.m(10);
				display: block;
			}
		}
		
		p {
			.m(8.8);
		}
		
		.tablet({
			grid-template-columns: 255px 1fr;

		});
	}
	
	.reservation + .reservation {
		margin-top: 30px;
	}
	
}

.playcare-member-rates {
	background-color: @grey-box-back;


	h1 {
		.g(30);
		text-align: center;
		color: @red;
	}
	
	.content {
		padding-bottom: 50px;
	}
	
	.badges-and-msg {
		display: grid;
		column-gap: 75px;
		
		.badges {
			.pass-button {
				display: block;
				margin-right: 0;
				margin-top: 10px;
			}
		}
		
		.tablet-and-smaller({
			.badges {
				grid-row: 2;
				grid-column: 1;
			}

			.msg {
				grid-row: 1;
				grid-column: 1;
			}
		});

		.tablet({
			grid-template-columns: 230px 1fr;

		});
	}
	
	.msg {
		p {
			.m(8.8);
			font-weight: 300;
		}
		li {
			.m(8.8);
			margin-bottom: 8px;
			font-weight: 300;
		}
	}
	
	
	.right, .left {
		.holder {
			height: 100%;
			img {
				height: 100%;
			}

		}
	}
	
}

.the-barky-bus {
	background-color: @red;
	
	.content {
		color: white;
		padding-top: 40px;
		padding-bottom: 40px;
		h1 {
			.g(30);
			margin: 0;
		}
		h2 {
			.m(12);
			margin: 0;
		}
		p {
			.m(9);
			font-weight: 300;
		}
	}
	
	.right {
		font-size: 0;
		.holder {
			font-size: 0;
			max-height: 100%;
			white-space: nowrap;
			padding-left: 40px;
			width: 100%;
			height: 100% !important;
			box-sizing: border-box;
		}
		picture {
			display: inline-block;
			font-size: 0;
			max-height: 100%;
			height: 100% !important;
			vertical-align: top;
		}
		img {
			display: block;
			border-radius: @corner-radius-std;
			max-height: 100%;
			height: 100% !important;
			width: auto !important;
		}
		picture + picture {
			margin-left: 10px;
		}
	}
	
	.pricing {
		display: grid;
		grid-template-columns: auto 1px 1fr;
		column-gap: 20px;
		.gap {
			font-size: 0;
			background-color: @yellow;
		}
		
		.ride {
			.m(22);
			font-weight: 600;
			sup {
				font-size: 50%;
			}
			small {
				.m(10);
				display: block;
			}

		}
	}
}