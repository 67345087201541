.pic-tile {
	border-radius: 18px;
	overflow: hidden;

	.use-picture-as-background;
	aspect-ratio: 0.78;
	display: inline-block;


	.msg {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;

		height: 45px;

		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;

		background-color: @blue-light;
		color: white;
		.gelica(17);
		font-weight: 600;
		padding: 10px;

		transition: background-color .3s, height .3s;

		user-select: none;
	}

	&:hover {
		.msg {
			background-color: fade(@blue-light, 40%);
			height: 100%;
		}
	}
}

.tile-links {
	text-align: center;
	margin-top: 40px;
	margin-bottom: 40px;

	.pic-tile {
		display: inline-block;
		width: 270px;
		margin: 10px;
	}
	.phone({
		.pic-tile {
			width: 312px;
		}

	});
}

.left-overlap-side-pic-slide {
	overflow: hidden;
	display: grid;
	overflow: hidden;
	padding-left: @page-side-padding;
	padding-right: @page-side-padding;
	row-gap: 20px;
	grid-template-rows: auto auto;

	.right {
		padding-right: @page-side-padding;
		grid-row: 1 / -1;
		grid-column: 1;
		
	}

	.left {
		position: relative;
		grid-row: 1 / span 2;
		grid-column: 1;

		.holder {
			position: absolute;
			// top: 0;
			right: 0px;
			top: 50%;
			transform: translateY(-50%);
			min-height: 100%;
			min-width: 100%;
			text-align: left;
			font-size: 0;
		}

		img {
			min-height: 100%;
		}
	}

	@padding-vert: 60px;
	.content,
	.pic-holder {
		z-index: 3;
	}

	.content {
		grid-row: 1;
		grid-column: 1;
		align-self: center;
		padding-top: @padding-vert;
		padding-bottom: @padding-vert;
	}
	.pic-holder {
		grid-row: 2;
		grid-column: 1;
		padding-bottom: @padding-vert;
		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			max-width: 100%;
		}
		figure {
			margin: 0;
		}
	}

	.tablet({
		grid-template-columns: 
			1fr 
			minmax(auto, 3 * @container-width / 10)
			minmax(auto, 3 * @container-width / 10) 
			minmax(auto, 4 * @container-width / 10) 
			1fr;
		.left {
			grid-column: 1 / span 2;
			grid-row: 1;
		}
		.right {
			grid-column: 5;
			grid-row: 1;
		}

		.content {
			grid-row: 1;
			grid-column: 2 / span 2;
		}
		.pic-holder {
			grid-row: 1;
			grid-column: 4;
			padding-top: @padding-vert;
		}
		
	});
}

.two-side-images {
	display: grid;
	grid-template-columns: 1fr minmax(auto, @container-width) 1fr;
	overflow: hidden;
	column-gap: 20px;

	.content {
		grid-row: 1;
		grid-column: 2;
	}
	.right {
		position: relative;
		grid-column: 3;
		grid-row: 1;
		.holder {
			position: absolute;
			// top: 0;
			left: 0px;
			top: 50%;
			transform: translateY(-50%);
			min-height: 100%;
			min-width: 100%;
			text-align: right;
			font-size: 0;
		}
		img {
			min-height: 100%;
		}
	}

	.left {
		position: relative;
		grid-column: 1;
		grid-row: 1;

		.holder {
			position: absolute;
			// top: 0;
			right: 0px;
			top: 50%;
			transform: translateY(-50%);
			min-height: 100%;
			min-width: 100%;
			text-align: left;
			font-size: 0;
		}

		img {
			min-height: 100%;
		}
	}
}

.right-overlap-side-pic-slide {
	overflow: hidden;
	display: grid;
	overflow: hidden;
	padding-left: @page-side-padding;
	padding-right: @page-side-padding;

	.content,
	.pic-holder {
		@padding-vert: 20px;
		padding-top: @padding-vert;
		padding-bottom: @padding-vert;
		z-index: 3;
	}
	
	.pic-holder {
		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
	
	.right {
		position: relative;
		grid-row: 1 / span 3;
		grid-column: 1;
		.holder {
			position: absolute;
			// top: 0;
			left: 0px;
			top: 50%;
			transform: translateY(-50%);
			min-height: 100%;
			min-width: 100%;
			text-align: right;
			font-size: 0;
		}
	}

	.top {
		position: relative;
		overflow: hidden;
		
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
	}

	.left {
		position: relative;

		.holder {
			position: absolute;
			// top: 0;
			right: 0px;
			top: 50%;
			transform: translateY(-50%);
			min-height: 100%;
			min-width: 100%;
			text-align: left;
			font-size: 0;
		}

		img {
			min-height: 100%;
		}
	}

	.content {
		grid-column: 1;
		grid-row: 1;
		align-self: center;
	}

	.tablet({
		grid-template-columns: 
			1fr 
			minmax(auto, 3 * @container-width / 10) 
			minmax(auto, 3 * @container-width / 10) 
			minmax(auto, 1 * @container-width / 10) 
			minmax(auto, 3 * @container-width / 10) 
			1fr;

		.pic-holder {
			grid-row: 1;
			grid-column: 4 / span 2;
		}
		.right {
			grid-column: 4 / span 3;
			grid-row: 1;
		}
		.top {
			grid-row: 1;
			grid-column: 1 / span 6;
		}
		.left {
			grid-column: 1 / span 2;
			grid-row: 1;
		}
		.content {
			grid-row: 1;
			grid-column: 2 / span 2;
		}
	});
	
	.desktop({
		.pic-holder {
			grid-row: 1;
			grid-column: 5;
		}

	});


	





}

.side-pic-slide {
	overflow: hidden;
	display: grid;
	grid-template-columns: 1fr minmax(auto, @container-width / 2) minmax(auto, @container-width / 2) 1fr;
	overflow: hidden;

	.left {
		padding-right: @page-side-padding;
		
	}
	.content {
		grid-row: 1;
		grid-column: 2 / span 2;

	}

	.right {
		position: relative;
		grid-row: 2;
		grid-column: 2 / span 2;

		.holder {
			position: absolute;
			// top: 0;
			left: 0px;
			bottom: 0;
			width: 100%;
			text-align: right;
			font-size: 0;
		}

		img {
			min-height: 100%;
		}
	}
	
	&.full-right {
		.right {
			.holder {
				top: 0;
				img {
					width: auto;
				}
			}
		}
	}
	
	.tablet({
		.content {
			grid-row: 1;
			grid-column: 2 / span 1;
		}
		
		.right {
			grid-row: 1;
			grid-column: 3 / span 2;
		}

	});

}


.color-button {
	display: inline-block;
	margin-right: 24px;
	text-decoration: none;
	color: @grey-body-text;
	background-color: @yellow;
	border-radius: 12px;
	padding: 22px 30px;
	min-width: 170px;
	transition: background-color .3s;
	text-align: center;
	font-weight: 800;
	.monteserrat(10);
	border: none;
	vertical-align: middle;

	&:hover {
		background-color: darken(@yellow, 10%);
	}
	&:last-child {
		margin-right: 0;
	}

	&.blue {
		color: white;
		background-color: @blue-dark;

		&:hover {
			background-color: darken(@blue-dark, 10%);
		}
	}

	&.yellow {
		background-color: @yellow;

		&:hover {
			background-color: darken(@yellow, 10%);
		}
	}
	
	&.purple {
		background-color: @purple;
		color: white;
		&:hover {
			background-color: darken(@purple, 10%);
		}
	}
	
	&.grey {
		background-color: @grey-box-back;
		&:hover {
			background-color: darken(@grey-box-back, 10%);
		}
	}
	
	&.compact {
		padding: 12px 30px;
	}
	
	.big-phone-and-smaller({
		margin-bottom: 12px;

	});
}

.color-button-blue {
	.color-button;
	.color-button.blue();
}

.color-button-purple {
	.color-button;
	.color-button.purple();
}

.color-red {
	color: @red !important;
}
.text-big {
	font-size: 140%;
}

.button-header {
	display: grid;
	column-gap: 20px;
	row-gap: 20px;

	h1 {
		.gelica(30);
		margin-bottom: 0;
		margin-top: 0;

		color: @red;
		text-align: center;

		small {
			display: block;
			color: @blue-light;
			.monteserrat(11);
		}

	}

	.action {
		text-align: center;

		a:last-child {
			margin-right: 0;
		}
	}

	&.left {
		h1 {
			text-align: left;
		}
	}
	
	.tablet({
		grid-template-columns: auto 1fr auto;
		h1 {
			grid-column: 1 / span 3;
			grid-row: 1;

		}
		.action {
			grid-column: 3;
			grid-row: 1;
			align-self: end;

		}

	});
}

.reel {
	padding-top: 50px;
	padding-bottom: 85px;

	.photos {
		margin-top: 50px;
		text-align: center;

		img {
			border-radius: 20px;
			margin: 10px;
			width: 270px;
			aspect-ratio: 1;
			object-fit: cover;
			object-position: center;
		}

	}
	.big-phone({
		.photos {
			columns: 2;
		}
	});
	.tablet({
		.photos {
			columns: 3;
		}
	});
	.desktop({
		.photos {
			columns: 4;
			picture:nth-child(4n + 3) {
				img {
					margin-top: 40px;
				}
			}
		}
	});
}

.inset-text {
	padding-top: 45px;
	padding-bottom: 45px;

	.container {
		padding-left: 20px;
		padding-right: 20px;
		text-align: center;
	}

	p {
		.monteserrat(9);
	}
	
	.tablet({
		.container {
			padding-left: 90px;
			padding-right: 90px;
			text-align: center;
		}

		p {
			.monteserrat(11);
		}
	

	});
}

.red-check {
	list-style: none;
	padding-left: 30px;

	li {
		.monteserrat(8);
		position: relative;
		font-weight: 500;
		margin-bottom: 10px;
		padding-left: 6px;

		&:before {
			content: url('/icons/checkmark-red.svg');
			position: relative;
			margin-left: -40px;
			top: 6px;
			padding-right: 16px;
		}
	}
}

.red-check-double-column {
	.red-check();
	columns: 2;
	column-gap: 50px;
	.big-phone-and-smaller({
		columns: unset;

	});
}

.black-check {
	.red-check;

	li {
		&:before {
			content: url('/icons/checkmark-black.svg');
		}
	}
}

.yellow-check {
	.red-check;

	li {
		&:before {
			content: url('/icons/checkmark-yellow.svg');
		}
	}
}

.call-to-schedule {
	.gelica(13);
	background-color: @yellow;
	padding: 12px 12px;
	text-align: center;
	color: @red;
	font-weight: 600;

	img {
		vertical-align: sub;
		width: 30px;
	}
}

.section-intro {
	padding-top: 50px;
	padding-bottom: 50px;

	.container {
		max-width: @container-width - 200;
		text-align: center;

		h1 {
			.gelica(30);
			color: @red;
			margin-top: 0;
			margin-bottom: 0;
		}

		h2 {
			.monteserrat(12);
			font-weight: 300;
		}

		p {
			.monteserrat(8);
			margin-top: 5px;
			margin-bottom: 5px;
		}
	}
}

.levels-of-grooming-service {
	box-sizing: border-box;
	margin-top: 30px;
	margin-bottom: 30px;
	padding-top: 40px;
	padding-bottom: 40px;
	padding-left: 20px !important;
	padding-right: 20px !important;

	background-color: @grey-box-back;
	border-radius: 18px;

	.tablet({
		padding-left: 90px !important;
		padding-right: 90px !important;

	});

}

.groom-package {
	.about {
		display: grid;
		column-gap: 65px;
		row-gap: 20px;
		
		.pic-holder {
			img {
				display: block;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.desc {
			h2 {
				.gelica(14);
				color: @red;
				margin-top: 0;
			}

			p {
				strong {
					color: @red;
				}
			}
		}
		.tablet-and-smaller({
			.pic-holder {
				img {
					max-width: 100%;
				}
			}

		});
		
		.tablet({
			grid-template-columns: auto 1fr;
			.pic-holder {
				position: relative;
				width: 220px;
				aspect-ratio: 1;
				align-self: start;

				img {
					position: absolute;
					top: 0;
					right: 0;
				}
			}


		});
	}

	.prices {
		display: flex;
		flex-direction: row;
		align-items: stretch;
		margin-top: 40px;

		.offering {
			display: grid;
			grid-template-rows: auto 1fr;
			margin-right: 1px;
			text-align: center;
			flex-grow: 1;

			p {
				margin: 0;
			}

			.price {
				background-color: white;

				p {
					.monteserrat(10);
					font-weight: 800;
					color: @red;
					padding-top: 20px;
					padding-bottom: 20px;

				}
			}

			.desc {
				margin-top: 1px;
				background-color: white;
				min-height: 6em;
				padding-top: 10px;
				padding-bottom: 10px;
				padding-left: 10px;
				padding-right: 10px;

				box-sizing: border-box;

				p {
					.monteserrat(9);
				}

				p:first-of-type {
					white-space: nowrap;
				}

				p+p {
					margin-top: 8px;
				}
			}

			&:last-child {
				margin-right: 0;
			}

		}
		
		.tablet-and-smaller({
			flex-direction: column;
			row-gap: 20px;
			.offering {
				background-color: @grey-box-lines;

			}

		});
		.tablet({
			background-color: @grey-box-lines;

		});
	}

	&+& {
		margin-top: 60px;
	}
}

.addons-box {
	background-color: @grey-box-back;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 30px;
	padding-right: 30px;

	border-radius: 18px;

	h2 {
		.gelica(14);
		color: @red;
		text-align: center;
	}

	h3 {
		.monteserrat(8);
		text-align: center;
		font-weight: 600;
	}
	
	.tablet-and-smaller({
		padding-left: 15px;
		padding-right: 15px;

	});
}

.addon-table {
	border-spacing: 1px;
	border-collapse: separate;
	width: 100%;
	position: relative;

	&:after {
		content: '\00a0';
		background-color: @grey-box-lines;
		position: absolute;
		top: 1px;
		left: 1px;
		right: 1px;
		bottom: 2px;
		z-index: 0;
	}

	small {
		.monteserrat(9);
		display: block;
		font-weight: 400;
	}

	td {
		background-color: white;
		padding: 10px 16px;
		position: relative;
		z-index: 2;
	}

	td:first-child {
		.monteserrat(10);
		font-weight: 600;
	}

	td:last-child {
		.gelica(10);
		color: @red;
		font-weight: 600;
		text-align: center;

	}
	.big-phone-and-smaller({
		td:first-child {
			.monteserrat(8);
			font-weight: 600;
		}

		td:last-child {
			.gelica(8);
		}
		small {
			.m(7);
		}

	});
}

.rounded-square-picture {
	img {
		border-radius: 20px;
		aspect-ratio: 1;
		object-fit: cover;
		object-position: center;
	}
}
.rounded-picture {
	img {
		border-radius: 20px;
		object-fit: cover;
		object-position: center;
	}
}
.pass-button {
	.m(22);
	font-weight: 600;
	display: inline-block;
	padding: 15px 10px;
	margin-right: 20px;
	background-color: #ede9e0;
	border-radius: @corner-radius-std;
	text-align: center;
	color: @blue-light;
	sup {
		font-size: 50%;
	}
	
	.duration {
		display: block;
		color: @grey-body-text;
		.m(10);
	}
}

.grey-back {
	background-color: @grey-box-back;
}

.two-level-text {
	display: inline-block;
	
	& > * {
		display: block;
	}

}

// .double-column {
// 	row-gap: 40px;
// }