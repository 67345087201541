
.temporary-paws {
	background-color: @blue-light;
	color: white;
	.content {
		padding-top: 50px;
		padding-bottom: 50px;
		
		
		h1 {
			.gelica(30);
			font-weight: 600;
			margin: 0;
			
			small {
				display: block;
				.monteserrat(11);
				font-weight: 500;
			}
		}
		
		p {
			.monteserrat(9);
			font-weight: 300;
			margin-top: 30px;
		}
		
		.big-phone-and-smaller({
			h1 {
				.gelica(20);
			}

		});
	}
	.right {
		.holder {
			left: 40px;
		}
		
		img.paws {
			margin-left: 50px;
		}
		
		.happy-dog-and-cat-picture {
			img {
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
	}
	
	.tablet-and-smaller({
		.content {
			padding-left: @page-side-padding;
		}
		.right {
			display: none;
		}
		

	});
}

.upcoming-events {
	background-color: @grey-box-back;
	padding-top: 50px;
	padding-bottom: 80px;
}