.no-scroll-bars {
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		// display: none;
		width: 0 !important;
	}
}

.circular-stripe {
	position: relative;
	overflow: auto;
	.no-scroll-bars;

	.mover {
		width: 4000px;
		box-sizing: border-box;
		font-size: 0;

		& > * {
			display: inline-block;
		}
		img {
			display: block;
		}
	}
}

.circular-stripe-holder {
	position: relative;
	.next, .previous {
		.m(24);
		position: absolute;
		top: 50%;
		background-color: @yellow;
		color: white;
		border: none;
		border-radius: @corner-radius-std;
		transform: translateY(-50%);
		width: 50px;
		height: 80px;
		padding-bottom: 5px;

	}
	.next {
		right: 20px;
	}
	.previous {
		left: 20px;
	}
}